<template>
<n-button
    v-if="!botShortInfo?.simulate"
    class="mr-3 mb-3 text-white/90 rounded-md"
    :color="gl.mainColor"
    @click="showResetFixOrder">
    {{ stats.localization['trade_statistics_management_resetfixorder_button'] }}
</n-button>

<base-modal-desktop
    v-model:show="show"
    width="800px"
    :label="stats.localization['new_rate_for_finish_cycle_order']">
    <template #default>
        <div class="flex items-center text-gray-600 dark:text-white/75">
            {{ stats.localization['finish_cycle_order__rate_to_finish_cycle_with_0_profit'] }}
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer pl-2"
                @click="onRateValueClicked(reSetFixOrder.rate_to_finish_cycle)">
                {{ reSetFixOrder.rate_to_finish_cycle }}
            </span>
        </div>
        <div class="flex text-sm items-center text-gray-600 dark:text-white/75">
            {{ stats.localization['finish_cycle_order__current_order_rate'] }}
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer pl-2"
                @click="onRateValueClicked(reSetFixOrder.rate_to_get_profit_hint)">
                {{ reSetFixOrder.rate_to_get_profit_hint }}
            </span>
        </div>
        <n-divider class="mt-3" />

        <div class="flex items-center text-base">
            <!-- <div>{{ stats.localization['finish_cycle_order__calculate_rate_to_get'] }}</div> -->
            <rb-input
                label-position="left"
                :label="stats.localization['finish_cycle_order__calculate_rate_to_get']"
                v-model:value="reSetFixOrder.rate_to_get_profit" />
            <span class="text-main font-bold px-2">%</span>
            <span class="whitespace-nowrap">{{ stats.localization['finish_cycle_order__profit'] }}</span>
            <n-button
                strong
                class="ml-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="isNaN(Number.parseFloat(reSetFixOrder.rate_to_get_profit, 10))"
                @click="onCalculateRateGetProfit">
                {{ t('calculate') }}
            </n-button>
        </div>
        <n-divider />

        <div class="flex items-center justify-start text-gray-600 dark:text-white/75">
            <span>{{ stats.localization['finish_cycle_order__current'] }}</span>
            <span class="mx-4 rb-td-stock-icon w-12" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
            <span>{{ stats.localization['finish_cycle_order__tickers_for'] }}</span>
            <div class="ml-4 flex items-center">
                <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
            </div>
            <span class="ml-4">{{ stats.localization['finish_cycle_order__buy'] }}:</span>
            <strong class="text-main font-bold underline hover:no-underline cursor-pointer ml-2" @click="onRateValueClicked(botShortInfo.buy)">&nbsp;{{ botShortInfo?.buy }}</strong>
            <span class="ml-4">{{ stats.localization['finish_cycle_order__sell'] }}:</span>
            <strong class="text-main font-bold underline hover:no-underline cursor-pointer ml-2" @click="onRateValueClicked(botShortInfo.sell)">&nbsp;{{ botShortInfo?.sell }}</strong>

            <n-button
                class="ml-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="showResetFixOrder">
                {{ stats.localization['finish_cycle_order__update'] }}
            </n-button>
        </div>
        <n-divider />

        <template v-if="calculateShow">
            <div class="flex items-center text-gray-600 dark:text-white/75">
                {{ stats.localization['finish_cycle_order__new_rate_with'] }}
                <span
                    class="text-main text-lg font-semibold px-2">
                    {{ reSetFixOrder.new_rate_to_get_profit_hint }}
                </span>
                {{ stats.localization['finish_cycle_order__profit'] }}
                <span
                    class="text-main text-lg font-semibold underline hover:no-underline cursor-pointer pl-2"
                    @click="onRateValueClicked(reSetFixOrder.new_rate_to_finish_cycle_suggest)">
                    {{ reSetFixOrder.new_rate_to_finish_cycle_suggest }}
                </span>
            </div>
            <n-divider />
        </template>

        <rb-input
            :label="stats.localization['finish_cycle_order__new_rate_to_finish_cycle']"
            label-position="left"
            v-model:value="reSetFixOrder.new_rate_to_finish_cycle" />
    </template>
    <template #footer>
        <div class="flex justify-end items-center">
            <rb-checkbox
                class="mr-4"
                :label="stats.localization['finish_cycle_order__stop_bot_after_finish_cycle_order_completed']"
                v-model:checked="reSetFixOrder.stop_bot_after_fix_completed" />
            <n-button
                :color="gl.mainColor"
                class="text-white/90 rounded-md"
                @click="doResetFixOrder"
                :disabled="isNaN(Number.parseFloat(reSetFixOrder.new_rate_to_finish_cycle, 10)) || reSetFixOrder.new_rate_to_finish_cycle < 0">
                {{ stats.localization['finish_cycle_order__reset_finish_cycle_order'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// vue
import { ref } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';

// i18n
import { useI18n } from 'vue-i18n';

// services
import StatsService from '@services/statsService';

// components
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';

// router
import { useRoute } from 'vue-router';

// UI
import {
    NButton,
    NDivider,
    useNotification } from 'naive-ui';

export default {
    name: 'reset-fix-order',
    props: {
        botShortInfo: {
            type: Object,
            required: true,
        },
    },
    components: {
        NButton,
        RbInput,
        NDivider,
        BaseModal,
        RbCheckbox,
    },
    setup(_, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const stats = useStats();

        // naive-ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // router
        const route = useRoute();

        // vars
        const show = ref(false);
        const calculateShow = ref(false);
        const RE_SET_FIX_ORDER_FACTORY = id => ({
            rate_to_finish_cycle: 0,
            rate_to_get_profit_hint: '',
            rate_to_get_profit: '',
            new_rate_to_finish_cycle: '',
            new_rate_to_get_profit_hint: '0%',
            new_rate_to_finish_cycle_suggest: 'Please, calculate',
    
            stop_bot_after_fix_completed: false,
        });
        const reSetFixOrder = ref(RE_SET_FIX_ORDER_FACTORY());

        const showResetFixOrder = async () => {
            gl.showLoading = true;

            try {
                const { id, rate_to_finish_cycle, rate_to_get_profit, status, errors } = ( await StatsService.getRateFinishCycle(route.params.id) ).data;

                // show messages
                if (!status) {
                    errors.forEach(({ msg }) => {
                        notification.error({
                            content: msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });
                } else {
                    const data = { rate_to_finish_cycle, rate_to_get_profit_hint: rate_to_get_profit };
                    reSetFixOrder.value = { ...reSetFixOrder.value, ...data };
                    show.value = true;
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            gl.showLoading = false;
        };

        const onCalculateRateGetProfit = async () => {
            gl.showLoading = true;

            const rate_to_get_profit = reSetFixOrder.value.rate_to_get_profit;
            const data = { _formPath: 'resetFixOrder', id: route.params.id, rate_to_get_profit };

            try {
                const recalcRateForProfit = ( await StatsService.recalcRateForProfit(data) ).data;

                reSetFixOrder.value = {...reSetFixOrder.value,
                    new_rate_to_get_profit_hint: rate_to_get_profit + '%',
                    new_rate_to_finish_cycle_suggest: recalcRateForProfit.new_rate_to_finish_cycle,
                };

                calculateShow.value = true;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            gl.showLoading = false;
        };

        const doResetFixOrder = async () => {
            gl.showLoading = true;

            const { new_rate_to_finish_cycle, stop_bot_after_fix_completed } = reSetFixOrder.value;
            const payload = { _formPath: 'resetFixOrder', id: route.params.id, new_rate_to_finish_cycle, stop_bot_after_fix_completed };

            try {
                const prepare = await StatsService.resetFixOrder(payload);

                if (prepare) {
                    // show messages
                    if (prepare.postMessages)
                        prepare.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                    
                    // show messages
                    if (prepare.data.errors)
                        prepare.data.errors.forEach(el => {
                            notification.error({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                }

                if (prepare.data.status) {
                    emit('getData');
                } else {
                    gl.showLoading = false;
                }

                show.value = false;
                
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
                gl.showLoading = false;
            };
        };

        const onRateValueClicked = new_rate_to_finish_cycle => {
            reSetFixOrder.value = { ...reSetFixOrder.value, new_rate_to_finish_cycle };
        };

        return {
            gl,
            show,
            stats,
            calculateShow,
            reSetFixOrder,
            t,
            doResetFixOrder,
            showResetFixOrder,
            onRateValueClicked,
            onCalculateRateGetProfit,
            currency: icon => refs.currencies.find(el => el.id === icon),
            exchange: exchange => refs.exchanges.find(el => el.id === exchange),
        };
    },
};
</script>